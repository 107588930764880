<template>
  <div class="input-group mb-4 mr-4">
    <div class="input-group-prepend">
      <button type="button" class="btn btn-danger" v-on:click="searchterm = ''" v-b-tooltip.bottom.hover title="Leeren"><fa-icon :icon="['far', 'times']" fixed-width /></button>
    </div>
    <input type="text" class="form-control" placeholder="Suche" v-model="searchterm">
  </div>
</template>

<script>
export default {
  name: 'SearchBarComponent',
  watch: {
    searchterm: function() {
      this.$emit('search', this.searchterm)
    }
  },
  data() {
    return {
      searchterm: ''
    }
  }
}
</script>