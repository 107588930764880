import SignInView from '../../views/SignInView.vue'
import SignedInView from '../../views/SignedInView.vue'
import OngoingListView from '../../views/OngoingListView.vue'
import DoneListView from '../../views/DoneListView.vue'
import ImportView from '../../views/ImportView.vue'
import NotAllowed from "../../views/NotAllowed.vue"

const routes = [
  { 
    path: '*',
    redirect: '/signin' 
  },
  { 
    path: '/', 
    redirect: '/signin' 
  },
  { 
    path: '/signin', 
    name: 'SignInView',
    component: SignInView,
    meta: {
      requiresAuth: false
    }
  },
  { 
    path: '/token/:token', 
    name: 'token',
    component: SignInView,
    meta: {
      requiresAuth: false
    }
  },
  { 
    path: '/notallowed', 
    name: 'NotAllowed',
    component: NotAllowed,
    meta: {
      requiresAuth: true
    }
  },
  { 
    path: '/a/list/ongoing/', 
    component: SignedInView,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '', 
        name: 'OngoingListView',
        component: OngoingListView
      },
      {
        path: '/a/list/done/', 
        name: 'DoneListView',
        component: DoneListView
      },
      {
        path: '/a/import/', 
        name: 'ImportView',
        component: ImportView
      }
   ] 
  }
]

export default routes