<template>
  <b-navbar toggleable="sm" type="dark" variant="primary">
    <b-container class="my-1" fluid>
      <b-navbar-brand class="d-flex align-items-center py-0">
        <LogoComponent :fill="'#ffffff'" :height="'32px'" :width="'32px'" class="mr-2" />Avid Renewal Manager
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" class="d-flex justify-content-between" is-nav>
        <b-navbar-nav>
          <b-nav-item :active="$route.name === 'OngoingListView'" :to="{ name: 'OngoingListView' }">Offen</b-nav-item>
          <b-nav-item :active="$route.name === 'DoneListView'" :to="{ name: 'DoneListView' }">Erledigt</b-nav-item>
          <b-nav-item :active="$route.name === 'ImportView'" :to="{ name: 'ImportView' }">Import</b-nav-item>
        </b-navbar-nav>
        <b-button variant="outline-light" v-on:click="signOut"><fa-icon :icon="['fas', 'sign-out']" /> Abmelden</b-button>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
import LogoComponent from '../components/LogoComponent.vue'
import { signOut, getAuth } from "firebase/auth";

export default {
  name: 'NavigationComponent',
  components: {
    LogoComponent
  },
  methods: {
    signOut: function() {
      signOut(getAuth()).then(() => {
        this.$router.push('signin')
      }).catch((error) => {
        console.error(error)
      });
    }
  }
}
</script>

<style scoped>
.container-fluid {
  max-width: 1480px;
}
</style>