<template>
  <div id="done-list-view">
    <TableComponent :list="doneList" :sortDesc="true" :sortDirection="'desc'" v-if="doneList.length > 0" />
    <div v-else>Keine Elemente zum Anzeigen</div>
  </div>
</template>

<script>
import TableComponent from '../components/TableComponent'

export default {
  name: 'OngoingListView',
  components: {
    TableComponent
  },
  props: {
    doneList: Array
  }
}
</script>