<template>
  <div id="import-view" class="w-25 mx-auto">

    <div class="form-group">
      <p class="font-weight-bold">Daten importieren</p>
      <label for="deluxe-report">Deluxe Report</label>
      <b-form-file id="deluxe-report" 
        placeholder="Wähle eine CSV-Datei"
        accept=".csv"
        required
        v-on:change="readDeluxeReport">
      </b-form-file>
    </div>
    <div class="form-group">
      <label for="avid-report">Avid Report</label>
      <b-form-file id="avid-report"
        placeholder="Wähle eine CSV-Datei"
        accept=".csv"
        required
        v-on:change="readAvidReport">
      </b-form-file>
    </div>
    <div class="form-group">
      Anzahl zu importierender Elemente: {{dbDataCount}}<br><br>
      <b-button  type="button" :variant="importVariant" :disabled="importButtonDisabled" v-on:click="writeToDb">
        <fa-icon v-if="importButtonState === 'saving'" :icon="['fad', 'spinner-third']" :spin="true" class="mr-1" />
        <fa-icon v-if="importButtonState === 'done'" :icon="['far', 'check']" class="mr-1" />
        Daten importieren
      </b-button>
    </div>

    <hr class="my-4">

    <div class="form-group">
      <label for="empty-db" class="font-weight-bold">Datenbank leeren</label>
      <p>Vorsicht, die Aktion kann nicht rückgängig gemacht werden!</p>
      <b-form-checkbox id="empty-db"
        v-model="emptyDbButtonDisabled"
        :value="false"
        :unchecked-value="true">
        Ja ich weiß, was ich tue
      </b-form-checkbox>
    </div>
    <b-button type="button" :variant="emptyVariant" :disabled="emptyDbButtonDisabled" v-on:click="emptyDb">
      <fa-icon v-if="emptyButtonState === 'saving'" :icon="['fad', 'spinner-third']" :spin="true" class="mr-1" />
      <fa-icon v-if="emptyButtonState === 'done'" :icon="['far', 'check']" class="mr-1" />
      Datenbank leeren
    </b-button>
  </div>
</template>

<script>
import Papa from 'papaparse'
import _ from 'lodash'

import { db, functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions"
import { ref, remove } from "firebase/database"

export default {
  name: 'ImportView',
  props: {
    doneList: Array,
    ongoingList: Array
  },
  computed: {
    importButtonDisabled: function() {
      if (this.dbData.length === 0) {
        return true
      }
      return false
    },
    importVariant: function() {
      if (this.importButtonState === 'done') {
        return 'success'
      } else if (this.importButtonState === 'saving') {
        return 'secondary'
      } else if (this.importButtonState === 'error') {
        return 'danger'
      }
      return 'dark'
    },
    emptyVariant: function() {
      if (this.emptyButtonState === 'done') {
        return 'success'
      } else if (this.emptyButtonState === 'saving') {
        return 'secondary'
      } else if (this.emptyButtonState === 'error') {
        return 'danger'
      }
      return 'dark'
    }
  },
  methods: {
    readDeluxeReport: function(input) {
      this.fileReader.onload = e => {
        const parsedData = Papa.parse(e.target.result)

        if (parsedData.data.length > 0) {
          parsedData.data.forEach((element, index) => {
            let importDataElement = {
              customernumber: element[0],
              ordernumber: element[1],
              serialnumber: element[2],
              email: '',
              purchasedate: '',
              renewaldate: '',
              hidden: false
            }

            if (element[1] === '') {
              importDataElement.customernumber = this.importData[index-1]['customernumber']
              importDataElement.ordernumber = this.importData[index-1]['ordernumber']
            }

            this.importData.push(importDataElement)
          })
        }
      }

      this.fileReader.readAsText(input.target.files[0])
    },
    readAvidReport: function(input) {
      this.fileReader.onload = e => {
        const parsedData = Papa.parse(e.target.result)

        if (parsedData.data.length > 0) {
          const serialnumbers = this.importData.map(element => element.serialnumber)

          parsedData.data.forEach(element => {
            const serialNumberIndex = serialnumbers.indexOf(element[2])

            if (serialNumberIndex !== -1) {
              const purchasedate = element[4].split('.');
              const renewaldate = element[5].split('.');

              this.importData[serialNumberIndex].email = element[3]
              this.importData[serialNumberIndex].purchasedate = `20${purchasedate[2]}-${purchasedate[1]}-${purchasedate[0]}`
              this.importData[serialNumberIndex].renewaldate = `20${renewaldate[2]}-${renewaldate[1]}-${renewaldate[0]}`
            }
          })

          this.importData.forEach(importDataElement => {
            const importElement = _.find(this.doneList.concat(this.ongoingList), existingElement => {
              return  existingElement.value.ordernumber === importDataElement.ordernumber  && 
                      existingElement.value.serialnumber === importDataElement.serialnumber && 
                      existingElement.value.purchasedate === importDataElement.purchasedate
            })
            if (importElement === undefined) {
              this.dbData.push(importDataElement)
            }
          })
        }

        this.dbDataCount = this.dbData.length
      }

      this.fileReader.readAsText(input.target.files[0])
    },
    writeToDb: async function() {
      this.importButtonState = 'saving'

      const writeToDb = httpsCallable(functions, 'writeToDb')

      await writeToDb(this.dbData)
      .then(() => this.importButtonState = 'done')
      .catch(error => {
        console.error(error)
        this.importButtonState = 'error'
      })
      .then(() => {
        setTimeout(() => {
          this.importButtonState = ''
        }, 3500)
      })

      this.dbDataCount = 0
    },
    emptyDb: async function() {
      await remove(ref(db, 'renewals'))
      .then(() => this.emptyButtonState = 'done')
      .catch(error => {
        console.error(error)
        this.emptyButtonState = 'error'
      })
      .then(() => {
        setTimeout(() => {
          this.emptyButtonState = ''
          this.emptyDbButtonDisabled = true
        }, 3500)
      })

      
    }
  },
  data () {
    return {
      fileReader: new FileReader(),
      importData: [],
      dbData: [],
      dbDataCount: 0,
      importButtonState: '',
      emptyDbButtonDisabled: true,
      emptyButtonState: ''
    }
  }
}
</script>