import {initializeApp} from 'firebase/app';
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {getDatabase, connectDatabaseEmulator} from "firebase/database";
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCd7EM0f6hiEkR5RblQC1l9-cN60RBFoN0",
  authDomain: "clgmbh-avidrenewalmanager.firebaseapp.com",
  databaseURL: "https://clgmbh-avidrenewalmanager.firebaseio.com",
  projectId: "clgmbh-avidrenewalmanager",
  storageBucket: "clgmbh-avidrenewalmanager.appspot.com",
  messagingSenderId: "237995126224",
  appId: "1:237995126224:web:6ba011f1b02ca0ed209dd5",
  measurementId: "G-B1X34P4PWZ"
};

const region = 'europe-west3';

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const db = getDatabase(firebase);
const functions = getFunctions(firebase, region);

if (location.hostname === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectDatabaseEmulator(db, 'localhost', 9001);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export {firebase, auth, functions, db, region} 