import Vue from 'vue'
import App from './App.vue'

import { auth } from "./plugins/firebase"
import { onAuthStateChanged } from 'firebase/auth' 

import { BootstrapVue } from 'bootstrap-vue'
import "@comlinedev/bootstrap4/dist/css/bootstrap.css";
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

import router from './plugins/router'
import './plugins/fontawesome'

Vue.config.productionTip = false

import moment from 'moment-timezone'

Vue.filter('convertDate', (date) => {
  return moment(date, 'YYYY.MM.DD').format('DD.MM.YY')
})

onAuthStateChanged(auth, () => {
  let app = ''
  if (!app) {
    app = new Vue({
      render: h => h(App),
      router
    }).$mount('#app')
  }
})
