<template>
  <div id="table-component">
    <div class="d-flex">
      <SearchBarComponent v-on:search="search" />
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Erledigt</th>
          <th>Kundennummer</th>
          <th>Auftragsnummer</th>
          <th>Seriennummer</th>
          <th>E-Mail-Adresse Endkunde</th>
          <th>Kaufdatum</th>
          <th>Renewaldatum</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(element) in orderedList" :key="`key-${element.key}`">
          <td><b-form-checkbox v-model="element.value.hidden" v-on:change="toggleCheckbox(element.key, element.value.hidden)" /></td>
          <td>{{element.value.customernumber}}</td>
          <td>{{element.value.ordernumber}}</td>
          <td>{{element.value.serialnumber}}</td>
          <td>{{element.value.email}}</td>
          <td>{{element.value.purchasedate | convertDate}}</td>
          <td>{{element.value.renewaldate | convertDate}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SearchBarComponent from './SearchBarComponent'
import _ from 'lodash'

export default {
  name: 'TableComponent',
  components: {
    SearchBarComponent
  },
  props: {
    list: Array,
    sortDirection: String
  },
  methods: {
    search: function(searchterm) {
      if (searchterm.match(/^([0-9]{2}\.+){1,3}([0-9]{2})*/gm)) {
        console.log('1')
        this.filterableList = _.filter(this.list, element => {
          if (
            _.includes(this.$options.filters.convertDate(element.value.purchasedate), searchterm) ||
            _.includes(this.$options.filters.convertDate(element.value.renewaldate), searchterm)
          ) {
            return true
          } else {
            return false
          }
        })
      } else {
        console.log('2')
        this.filterableList = _.filter(this.list, element => {
          if (
            _.includes(element.value.customernumber.toLowerCase(), searchterm.toLowerCase()) ||
            _.includes(element.value.ordernumber.toLowerCase(), searchterm.toLowerCase()) ||
            _.includes(element.value.serialnumber.toLowerCase(), searchterm.toLowerCase()) ||
            _.includes(element.value.email.toLowerCase(), searchterm.toLowerCase())
          ) {
            return true
          } else {
            return false
          }
        })
      }
    },
    toggleCheckbox: function(key, value) {
      if (value) {
        this.$database.ref(`renewals/${key}/hidden`).set(false)
      } else {
        this.$database.ref(`renewals/${key}/hidden`).set(true)
      }
    }
  },
  computed: {
    orderedList: function() {
      return _.orderBy(this.filterableList, element => {
        return element.value.renewaldate
      }, this.sortDirection)
    }
  },
  watch: {
    list: {
      handler: function(list) {
        this.filterableList = list
      },
      deep: true
    }
  },
  data() {
    return {
      searchterm: '',
      filterableList: this.list
    }
  }
}
</script>

<style>
.table th {
  border-top: 0 !important;
}
.table tr td {
  white-space: nowrap;
}
.table tr td:nth-child(5) {
  white-space: normal;
}
</style>