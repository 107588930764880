<template>
  <div id="ongoing-list-view">
    <TableComponent :list="ongoingList" :sortDesc="false" :sortDirection="'asc'" v-if="ongoingList.length > 0" />
    <div v-else>Keine Elemente zum Anzeigen</div>
  </div>
</template>

<script>
import TableComponent from '../components/TableComponent'

export default {
  name: 'OngoingListView',
  components: {
    TableComponent
  },
  props: {
    ongoingList: Array
  }
}
</script>